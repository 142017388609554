import Immutable from 'immutable';
import * as types from './PqtDepartment.types';

const initialState = {
    id: null,
    fetching: {
        information: false,
        jobs: false,
        department: false,
    },
    data: {
        jobs: [],
        operators: [],
    },
    pagination: {
        jobs: {
            totalElements: 0,
            activePage: 1,
            totalPages: 0,
            elementsPerPage: 15,
        },
    },
    sort: {
        column: 'due_date',
        type: 'asc',
    },
    information: {
        departments: [],
        salesReps: [],
        csrs: [],
    },
    searchFields: {
        jobNumber: '',
        date: '',
        csr: '',
        salesRep: '',
    },
    department: null,
    history: null,
    closeOutFields: {
        closing: false,
        savingPartial: false,
        savingShipping: false,
        savingMailing: false,
        tasks: [],
        crewInformation: [],
        jobDetailsList: [],
    },
    barcode: {
        value: '',
        isCapturing: false,
    },
    showJobs: 1,
    maildatFiles: [],
    uploadingMaildatFiles: null,
    closeJobId: null,
    jobClosed: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_DEPARTMENT:
            return state.setIn(['fetching', 'department'], true).toJS();

        case types.FETCH_DEPARTMENT_SUCCESS:
            return state.setIn(['fetching', 'department'], false)
                .setIn(['department'], action.payload)
                .toJS();

        case types.FETCH_DEPARTMENT_ERROR:
            return state.setIn(['fetching', 'department'], false).toJS();

        case types.FETCH_JOBS:
            return state.setIn(['fetching', 'jobs'], !action.payload).toJS();

        case types.FETCH_JOBS_SUCCESS:
            return state.setIn(['fetching', 'jobs'], false)
                .setIn(['data', 'jobs'], action.payload.elements)
                .setIn(['pagination', 'jobs', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'jobs', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'jobs', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'jobs', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_JOBS_ERROR:
            return state.setIn(['fetching', 'jobs'], false).toJS();

        case types.FETCH_OPERATORS_SUCCESS:
            return state.setIn(['data', 'operators'], action.payload).toJS();

        case types.SET_ID:
            return state.setIn(['id'], action.payload.id)
                .setIn(['showJobs'], action.payload.showJobs).toJS();

        case types.SET_SEARCH_FIELD:
            return state.setIn(['searchFields', action.payload.name], action.payload.value).toJS();

        case types.CLOSE_OUT_JOB:
            return state.setIn(['closeOutFields', 'closing'], true).toJS();

        case types.CLOSE_OUT_JOB_SUCCESS:
            return state.setIn(['closeOutFields', 'closing'], false)
                .setIn(['maildatFiles'], [])
                .toJS();

        case types.CLOSE_OUT_JOB_ERROR:
            return state.setIn(['closeOutFields', 'closing'], false).toJS();

        case types.SAVE_PARTIAL:
            return state.setIn(['closeOutFields', 'savingPartial'], true).toJS();

        case types.SAVE_PARTIAL_SUCCESS:
            return state.setIn(['closeOutFields', 'savingPartial'], false).toJS();

        case types.SAVE_PARTIAL_ERROR:
            return state.setIn(['closeOutFields', 'savingPartial'], false).toJS();

        case types.SAVE_SHIPPING:
            return state.setIn(['closeOutFields', 'savingShipping'], true).toJS();

        case types.SAVE_SHIPPING_SUCCESS:
            return state.setIn(['closeOutFields', 'savingShipping'], false).toJS();

        case types.SAVE_SHIPPING_ERROR:
            return state.setIn(['closeOutFields', 'savingShipping'], false).toJS();

        case types.SAVE_MAILING:
            return state.setIn(['closeOutFields', 'savingMailing'], true).toJS();

        case types.SAVE_MAILING_SUCCESS:
            return state.setIn(['closeOutFields', 'savingMailing'], false).toJS();

        case types.SAVE_MAILING_ERROR:
            return state.setIn(['closeOutFields', 'savingMailing'], false).toJS();

        case types.UPLOAD_MAILDAT_FILE:
            return state.setIn(['uploadingMaildatFiles'], true).toJS();

        case types.UPLOAD_MAILDAT_FILE_SUCCESS:
            return state.setIn(['uploadingMaildatFiles'], false).toJS();

        case types.UPLOAD_MAILDAT_FILE_ERROR:
            return state.setIn(['uploadingMaildatFiles'], false).toJS();

        case types.SET_CLOSE_OUT_FIELD:
            return state.setIn(['closeOutFields', action.payload.name], action.payload.value).toJS();

        case types.SELECT_JOB:
            return state.setIn(['closeOutFields'], action.payload)
                .setIn(['maildatFiles'], [])
                .toJS();

        case types.SET_BARCODE:
            return state.setIn(['barcode', 'value'], action.payload).toJS();

        case types.SET_BARCODE_CAPTURING:
            return state.setIn(['barcode', 'isCapturing'], action.payload).toJS();

        case types.SET_SORT:
            return state.setIn(['sort'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', action.payload.name, 'activePage'], action.payload.value).toJS();

        case types.ADD_TASK:
            return state.updateIn(['closeOutFields', 'tasks'], (tasks) => {
                if (action.payload.departmentId && action.payload.departmentId === 56) {  // Postal Accounting
                    let partials = [{
                        project_mail: action.payload.job.project_mail,
                        mail_type: action.payload.job.mail_type,
                        permit_number: action.payload.job.permit_number,
                        post_office: action.payload.job.post_office,
                        final_quantity: '',
                        price: '',
                    }];
                    if (action.payload.job.is_mail_project_2) {
                        partials.push({
                            project_mail: action.payload.job.project_mail_2,
                            mail_type: action.payload.job.mail_type_2,
                            permit_number: action.payload.job.permit_number_2,
                            post_office: action.payload.job.post_office_2,
                            final_quantity: '',
                            price: '',
                        });
                    }

                    return tasks.push({
                        id: action.payload.id,
                        equipment: '',
                        final_quantity: '',
                        partials: partials,
                    });
                } else if (action.payload.departmentId && action.payload.departmentId === 17) {  // Shipping
                    return tasks.push({
                        id: action.payload.id,
                        shippings: [{
                            quantity: '',
                            shipping_method: '',
                            tracking_number: '',
                            shipping_cost: '',
                        }]
                    });
                } else {
                    return tasks.push({ id: action.payload.id, equipment: '', final_quantity: '' });
                }
            }).toJS();

        case types.REMOVE_TASK:
            return state.updateIn(['closeOutFields', 'tasks'], (tasks) => {
                const index = tasks.findIndex(task => parseInt(task.get('id')) === parseInt(action.payload));
                return tasks.delete(index);
            }).toJS();

        case types.SET_TASK_FIELD:
            return state.updateIn(['closeOutFields', 'tasks'], (tasks) => {
                const index = tasks.findIndex(task => parseInt(task.get('id')) === parseInt(action.payload.id));
                const task = tasks.get(index);
                if (!task) {
                    return tasks;
                }
                return tasks.set(index, task.setIn([action.payload.field], action.payload.value));
            }).toJS();

        case types.SET_SHOW_JOBS:
            return state.setIn(['showJobs'], action.payload).toJS();

        case types.SET_PARTIAL_FIELD:
            return state.updateIn(['closeOutFields', 'tasks'], (tasks) => {
                const tasksArray = tasks.toJS();
                tasksArray[0].partials[action.payload.index][action.payload.field] = action.payload.value;
                return tasksArray;
            }).toJS();

        case types.ADD_PARTIAL:
            return state.updateIn(['closeOutFields', 'tasks'], (tasks) => {
                const tasksArray = tasks.toJS();
                tasksArray[0].partials.push({
                    project_mail: '',
                    mail_type: '',
                    permit_number: '',
                    post_office: '',
                    final_quantity: '',
                    price: '',
                });
                return tasksArray;
            }).toJS();

        case types.REMOVE_PARTIAL:
            return state.updateIn(['closeOutFields', 'tasks'], (tasks) => {
                const tasksArray = tasks.toJS();
                if (tasksArray[0].partials.length > 0) {
                    tasksArray[0].partials.pop();
                }
                return tasksArray;
            }).toJS();

        case types.SET_SHIPPING_FIELD:
            return state.updateIn(['closeOutFields', 'tasks'], (tasks) => {
                const tasksArray = tasks.toJS();
                tasksArray[0].shippings[action.payload.index][action.payload.field] = action.payload.value;
                return tasksArray;
            }).toJS();

        case types.ADD_SHIPPING:
            return state.updateIn(['closeOutFields', 'tasks'], (tasks) => {
                const tasksArray = tasks.toJS();
                tasksArray[0].shippings.push({
                    quantity: '',
                    shipping_method: '',
                    tracking_number: '',
                    shipping_cost: '',
                });
                return tasksArray;
            }).toJS();

        case types.REMOVE_SHIPPING:
            return state.updateIn(['closeOutFields', 'tasks'], (tasks) => {
                const tasksArray = tasks.toJS();
                if (tasksArray[0].shippings.length > 0) {
                    tasksArray[0].shippings.pop();
                }
                return tasksArray;
            }).toJS();

        case types.ADD_CREW_PERSON:
            return state.updateIn(['closeOutFields', 'crewInformation'], (crewInformation) => {
                return crewInformation.push({
                    operator: '',
                    roll: '',
                    dateIn: null,
                    dateOut: null,
                    timeIn: null,
                    timeOut: null,
                    startCount: '',
                    endCount: '',
                    machine: '',
                });
            }).toJS();

        case types.SET_CREW_PERSON_FIELD:
            return state.updateIn(['closeOutFields', 'crewInformation'], (crewInformation) => {
                const items = crewInformation.toJS();
                items[action.payload.index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.DELETE_CREW_PERSON:
            return state.updateIn(['closeOutFields', 'crewInformation'], (crewInformation) => {
                return crewInformation.delete(action.payload);
            }).toJS();

        case types.ADD_JOB_DETAIL_LINE:
            return state.updateIn(['closeOutFields', 'jobDetailsList'], (jobDetailsList) => {
                return jobDetailsList.push({
                    skid: '',
                    drop: '',
                    sacks: '',
                    oneTrays: '',
                    twoTrays: '',
                    twoeTrays: '',
                    tubs: '',
                });
            }).toJS();

        case types.SET_JOB_DETAIL_LINE_FIELD:
            return state.updateIn(['closeOutFields', 'jobDetailsList'], (jobDetailsList) => {
                const items = jobDetailsList.toJS();
                items[action.payload.index][action.payload.field] = action.payload.value;
                return items;
            }).toJS();

        case types.DELETE_JOB_DETAIL_LINE:
            return state.updateIn(['closeOutFields', 'jobDetailsList'], (jobDetailsList) => {
                return jobDetailsList.delete(action.payload);
            }).toJS();

        case types.ADD_MAILDAT_FILES:
            return state.setIn(['maildatFiles'], action.payload).toJS();

        case types.SET_CLOSE_JOB_ID:
            return state.setIn(['closeJobId'], action.payload).toJS();

        case types.SET_JOB_CLOSED:
            return state.setIn(['jobClosed'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}